import { colors, jackColors } from "../../../assets/colors";
import { TextFieldJack } from "../../../components/inputs/textfield";
import { GothamRegular, TextInlineMedium } from "../../../components/Text";
import { Layout } from "../components";
import { useForm } from "@tools";
import styles from "../layout.module.css";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { LoginInline } from "./inline";
import { SuspendedUserModal } from "../login/otp";
import { useRouter } from "next/router";

const ForgotPasswordPageComponents = ({
  setCurrentScreen,
  setEmailSent,
  mutation,
  loading,
}) => {
  const useFormObj = useForm();
  const { handleSubmit, watch } = useFormObj;
  const { query, pathname, push } = useRouter();

  const emailWatch = watch("email");
  const isButtonDisabled = !Boolean(emailWatch);

  const loginHandler = () => {
    setCurrentScreen("login");
  };

  const submit = async (data) => {
    try {
      await mutation(data);
      setEmailSent(data.email);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout title="Forgot your password?" setCurrentScreen={setCurrentScreen}>
      <GothamRegular
        style={{
          color: colors.neutral700,
          textAlign: "center",
          marginTop: "-24px",
        }}
      >
        You’re in good hands. We'll email you a link to reset your password.
      </GothamRegular>
      <form className={styles["form"]} onSubmit={handleSubmit(submit)}>
        <TextFieldJack
          name="email"
          label="Email"
          placeholder="Enter your email"
          useFormObj={useFormObj}
        />
        <ButtonJack
          className={styles["button"]}
          isLoading={loading}
          disabled={isButtonDisabled}
        >
          Forgot Password
        </ButtonJack>
      </form>
      <GothamRegular style={{ color: jackColors.grey90 }}>
        Remember your password?
        <LoginInline onClick={loginHandler} />
      </GothamRegular>
      <SuspendedUserModal
        condition={query?.suspend}
        isOpen={Boolean(query?.suspend)}
        toggle={() => {
          const { suspend, ...rest } = query;
          push({ pathname, query: { ...rest } });
        }}
      />
    </Layout>
  );
};

export default ForgotPasswordPageComponents;
