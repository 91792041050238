import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useMutation } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";
import CheckEmailPageComponents from "./checkEmail/parent";
import ForgotPasswordPageComponents from "./forgotPassword/parent";
import LoginPageComponents from "./login/parent";
import RegisterPageComponents from "./register/parent";
import { TurnstilePage } from "./login/turnstile";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

const CurrentScreenDecider = () => {
  const [currentScreen, setCurrentScreen] = useState("login");
  const [emailSent, setEmailSent] = useState("");

  const { pathname, query, push } = useRouter();

  useEffect(() => {
    const isRegister = pathname.includes("register");
    const isNewRegister = pathname.includes("new-register");
    if (isRegister) setCurrentScreen("register");
    if (isNewRegister) setCurrentScreen("new-register");
  }, [pathname]);

  const { mutation: mutationForgotPassword, loading: loadingForgotPassword } =
    useMutation({
      url: "/business_users/forgot_password",
      method: "post",
      afterSuccess: (_, payload) => {
        eventsTracker("forgot_password", payload);
        setCurrentScreen("checkEmailForgotPassword");
      },
      handleError: (errorObj) => {
        const {
          error,
          user_status,
          failed_password_attempt,
          failed_pin_attempt,
          failed_otp_attempt,
          ...rest
        } = errorObj?.response?.data || {};
        const isSuspended = user_status == "suspended";

        if (isSuspended) {
          const suspendCausedByPIN = failed_pin_attempt >= 3;
          const suspendCausedByPassword = failed_password_attempt >= 3;
          const suspendCausedByOTP = failed_otp_attempt >= 3;

          const condition = suspendCausedByPassword
            ? "password"
            : suspendCausedByPIN
            ? "pin"
            : suspendCausedByOTP
            ? "otp"
            : "";

          return push({ pathname, query: { ...query, suspend: condition } });
        }
      },
    });

  const { mutation: mutationResendRegister } = useMutation({
    url: "/business_users/resend_confirmation",
    method: "post",
    afterSuccess: (_, payload) => eventsTracker("resend_register", payload),
  });

  const { getData } = useVisitorData(
    { ignoreCache: true },
    { immediate: false }
  );

  const forgotPassword = async (value) => {
    const { requestId: salus } = await getData();
    mutationForgotPassword({ ...value, salus });
  };

  switch (currentScreen) {
    case "register":
      return (
        <RegisterPageComponents
          setCurrentScreen={setCurrentScreen}
          setEmailSent={setEmailSent}
        />
      );
    // case "new-register":
    //   return (
    //     <RegisterPageComponents
    //       setCurrentScreen={setCurrentScreen}
    //       setEmailSent={setEmailSent}
    //       banner={query.banner || "general"}
    //     />
    //   );
    case "checkEmailForgotPassword":
    case "checkEmailRegister":
      return (
        <CheckEmailPageComponents
          type={currentScreen}
          emailSent={emailSent}
          setCurrentScreen={setCurrentScreen}
          mutationForgotPassword={forgotPassword}
          mutationResendRegister={mutationResendRegister}
        />
      );
    case "forgotPassword":
      return (
        <ForgotPasswordPageComponents
          setCurrentScreen={setCurrentScreen}
          setEmailSent={setEmailSent}
          loading={loadingForgotPassword}
          mutation={forgotPassword}
        />
      );
    default:
      return (
        <TurnstilePage>
          <LoginPageComponents setCurrentScreen={setCurrentScreen} />
        </TurnstilePage>
      );
  }
};

export const LoginPage = () => {
  const { query } = useRouter();
  const { token } = query;

  const { mutation } = useMutation({
    url: "/business_users/confirm_email",
    method: "post",
    withError: false,
  });

  useEffect(() => {
    if (!token) return;
    mutation({ token });
  }, [token]);

  return (
    <>
      <CurrentScreenDecider />
    </>
  );
};
