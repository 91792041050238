import { useEffect, useState } from "react";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import { useRouter } from "next/router";
import { EventRegisterForm } from "./events";
import { encryptToken } from "../../../tools/api";
import { TurnstilePage } from "../login/turnstile";

const RegisterPageComponents = ({
  setCurrentScreen,
  setEmailSent,
  // banner = null,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [payload, setPayload] = useState({});
  const isSecondStep = currentStep === 2;

  const { query, replace, push, isReady } = useRouter();
  // const { event, utm_source = "", utm_medium = "", utm_campaign = "" } = query;
  const { event } = query;

  // const marketingData = {
  //   utm_source,
  //   utm_medium,
  //   utm_campaign,
  // };

  const isValidEvent = new Date("9/1/2023").getTime() > new Date().getTime();

  const isDesty = isValidEvent && event == "desty";

  useEffect(() => {
    if (!event) return;
    if (isDesty) return;
    replace("/register");
  }, [event]);

  const isEvent = currentStep == "event";

  if (!isReady) return null;

  if (isSecondStep)
    return (
      <TurnstilePage>
        <SecondStep
          payload={payload}
          setCurrentStep={() => setCurrentStep(event ? "event" : 1)}
          setEmailSent={setEmailSent}
          setCurrentScreen={setCurrentScreen}
        />
      </TurnstilePage>
    );

  if (isEvent)
    return (
      <EventRegisterForm
        onSubmit={(additional_info) => {
          setPayload((prev) => ({ ...prev, additional_info }));
          setCurrentStep(2);
        }}
        defaultValue={payload.additional_info}
      />
    );

  return (
    <FirstStep
      setEmailSent={setEmailSent}
      setCurrentStep={() => {
        if (!isEvent) return;
        if (isDesty) return setCurrentStep("event");
        setCurrentStep(2);
      }}
      // banner={banner}
      payload={payload}
      setPayload={(updatedPayload) => {
        if (isEvent) return setPayload(updatedPayload);

        const { user: { password, password_confirmation } = {} } =
          updatedPayload || {};

        push({
          pathname: "/register/second/",
          query: {
            ...updatedPayload?.user,
            password: encryptToken(password),
            password_confirmation: encryptToken(password_confirmation),
            ...marketingData,
          },
        });
      }}
      setCurrentScreen={setCurrentScreen}
    />
  );
};

export default RegisterPageComponents;
